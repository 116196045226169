import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import Links from "../Components/Links/Links";
import Footer from "../Footer/Footer";
import Loader from "../Components/Loader/Loader";

// Lazy load components
const Home = lazy(() => import("../Home/Home"));
const Services = lazy(() => import("../Services/Services"));
const AboutUs = lazy(() => import("../AboutUs/AboutUs"));
const Contacts = lazy(() => import("../Contacts/Contacts"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Pages = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="overflow-hidden bg-white">
        <Navbar />
        <Links />
        <div className="pt-24 md:pt-20">
          {loading ? (
            <div className="min-h-screen mt-40">
              <Loader />
            </div>
          ) : (
            <Suspense
              fallback={
                <div className="min-h-screen mt-40">
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contacts" element={<Contacts />} />
              </Routes>
            </Suspense>
          )}
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default Pages;
