import React from "react";

const TermsOfServiceModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-8 rounded-lg max-w-lg w-[90%] h-[80%] overflow-y-scroll flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
        <p className="mb-4">
          Welcome to Everest Radiator Service Company. By using our services,
          you agree to the following terms and conditions.
        </p>
        <h3 className="text-xl font-semibold mb-2">Use of Services</h3>
        <p className="mb-4">
          You agree to use our services only for lawful purposes and in
          accordance with these Terms of Service. You must not use our services
          in any way that could damage, disable, overburden, or impair our
          services, or interfere with any other party's use and enjoyment of our
          services.
        </p>
        <h3 className="text-xl font-semibold mb-2">Intellectual Property</h3>
        <p className="mb-4">
          All content included in our services, such as text, graphics, logos,
          images, and software, is the property of Everest Radiator Service
          Company or its content suppliers and is protected by intellectual
          property laws. You may not reproduce, distribute, or create derivative
          works from any content without our express written permission.
        </p>
        <h3 className="text-xl font-semibold mb-2">User Accounts</h3>
        <p className="mb-4">
          If you create an account with us, you are responsible for maintaining
          the confidentiality of your account and password and for restricting
          access to your computer. You agree to accept responsibility for all
          activities that occur under your account or password.
        </p>
        <h3 className="text-xl font-semibold mb-2">Termination</h3>
        <p className="mb-4">
          We may terminate or suspend your access to our services immediately,
          without prior notice or liability, for any reason whatsoever,
          including without limitation if you breach these Terms of Service.
        </p>
        <h3 className="text-xl font-semibold mb-2">Limitation of Liability</h3>
        <p className="mb-4">
          In no event shall Everest Radiator Service Company, nor its directors,
          employees, partners, agents, suppliers, or affiliates, be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your use or inability to
          use our services; (ii) any unauthorized access to or use of our
          servers and/or any personal information stored therein; (iii) any
          interruption or cessation of transmission to or from our services;
          (iv) any bugs, viruses, trojan horses, or the like that may be
          transmitted to or through our services by any third party; (v) any
          errors or omissions in any content or for any loss or damage incurred
          as a result of the use of any content posted, emailed, transmitted, or
          otherwise made available through our services; and/or (vi) any other
          matter relating to our services.
        </p>
        <h3 className="text-xl font-semibold mb-2">Governing Law</h3>
        <p className="mb-4">
          These Terms of Service shall be governed and construed in accordance
          with the laws of the jurisdiction in which Everest Radiator Service
          Company operates, without regard to its conflict of law provisions.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Changes to Terms of Service
        </h3>
        <p className="mb-4">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms of Service at any time. If a revision is material, we will
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
        <p className="mb-4">
          If you have any questions about these Terms of Service, please contact
          us at everestradiatorservice@gmail.com.
        </p>
        <button
          onClick={onClose}
          className="bg-primary text-white px-4 py-2 rounded-full hover:opacity-75 transition-opacity duration-300 mt-auto"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsOfServiceModal;
