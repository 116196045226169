import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { NavLink } from "react-router-dom";

import AnimatedSection from "../Components/Animation/AnimatedSection";
import ZoomAnimated from "../Components/Animation/ZoomAnimated";
import FooterLogo from "./footerlogo.webp";
import PrivacyPolicyModal from "./Modals/PrivacyPolicyModal";
import TermsOfServiceModal from "./Modals/TermsOfServiceModal";
import Map from "../Components/Map/Map";

const Footer = () => {
  const [state, handleSubmit] = useForm("mgveynkw");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);

  const handleClose = () => {
    setShowPrivacyPolicy(false);
    setShowTermsOfService(false);
  };

  return (
    <>
      <Map />
      <div className="bg-primary py-12">
        <div className="flex items-center justify-center lg:px-10 lg:justify-between flex-col lg:flex-row">
          <div className="text-white p-5">
            <AnimatedSection className="font-semibold text-3xl mb-3">
              Stay Connected With Us...
            </AnimatedSection>
            <AnimatedSection className="font-secondary text-sm lg:w-96">
              For the latest industry trends, product innovations, and exclusive
              offers from Everest Radiators, Join our Community!
            </AnimatedSection>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row items-center justify-center gap-20 pt-10 lg:pt-0 w-full px-5"
          >
            <ZoomAnimated className="w-full md:w-1/3">
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Your Name"
                className="w-full border-b-2 border-slate-400 focus:outline-none py-2 bg-primary text-slate-400"
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </ZoomAnimated>
            <ZoomAnimated className="w-full md:w-1/3">
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Your Email"
                className="w-full border-b-2 border-slate-400 focus:outline-none py-2 bg-primary text-slate-400"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </ZoomAnimated>
            <ZoomAnimated>
              <button
                type="submit"
                disabled={state.submitting}
                className="flex items-center text-sm bg-white w-60 text-center justify-center text-black px-4 py-3 rounded-full hover:opacity-75 transition-opacity duration-300"
              >
                {state.succeeded ? (
                  "Thanks for joining!"
                ) : (
                  <>
                    Subscribe
                    <svg
                      className="w-5 h-5 ml-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </>
                )}
              </button>
            </ZoomAnimated>
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col w-full">
        <div className="flex items-center justify-center flex-col md:flex-row md:justify-between w-full p-20">
          <ZoomAnimated>
            <img src={FooterLogo} alt="Footer Logo" className="w-full" />
          </ZoomAnimated>
          <div className="flex flex-col items-center justify-center md:flex-row gap-4 gap-x-10 mt-5 md:mt-0">
            <NavLink
              to="/"
              className="text-black font-semibold text-lg hover:text-slate-400 transition-colors duration-300"
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className="text-black font-semibold text-lg hover:text-slate-400 transition-colors duration-300"
            >
              Services
            </NavLink>
            <NavLink
              to="/about"
              className="text-black font-semibold text-lg hover:text-slate-400 transition-colors duration-300"
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              className="text-black font-semibold text-lg hover:text-slate-400 transition-colors duration-300"
            >
              Contact Us
            </NavLink>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col md:flex-row md:justify-between w-full md:px-20">
          <AnimatedSection className="text-center text-black font-semibold text-xs font-primary lg:text-lg">
            © 2024 Everest Radiators. All Rights Reserved. <br />
            Designed and Developed by{" "}
            <span className="text-primary">
              <a
                href="https://ansaribrahim.me/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                Ansar Ibrahim
              </a>
            </span>
          </AnimatedSection>
          <div className="flex items-center justify-center my-8 font-primary">
            <ZoomAnimated>
              <button
                onClick={() => setShowPrivacyPolicy(true)}
                className="text-slate-400 mx-4 hover:underline"
              >
                Privacy Policy
              </button>
            </ZoomAnimated>
            <ZoomAnimated>
              <button
                onClick={() => setShowTermsOfService(true)}
                className="text-slate-400 mx-4 hover:underline"
              >
                Terms of Service
              </button>
            </ZoomAnimated>
          </div>

          <PrivacyPolicyModal
            isOpen={showPrivacyPolicy}
            onClose={handleClose}
          />
          <TermsOfServiceModal
            isOpen={showTermsOfService}
            onClose={handleClose}
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
