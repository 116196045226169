import React from "react";

import everestLogo from "../../assets/everlogo.webp";

const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative flex items-center justify-center">
        <img src={everestLogo} alt="Zetha Logo" className="z-10" />
        <div
          className="absolute h-60 w-60 rounded-full border-4 border-transparent animate-spin"
          style={{
            borderTopColor: "#F28B8E", // Light shade of #C32026
            borderRightColor: "#E04A4E", // Medium-light shade of #C32026
            borderBottomColor: "#C32026", // Medium-dark shade of #C32026
            borderLeftColor: "#8E1A1C", // Dark shade of #C32026
          }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
