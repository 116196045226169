import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import AnimatedSection from "../Components/Animation/AnimatedSection";
import everestlogo from "./headerlogo.webp";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AnimatedSection className="w-full z-50 bg-neutral-50 text-secondary font-clash-display fixed shadow-md">
      <nav className="container mx-auto flex items-center justify-between p-5">
        <div className="flex items-center">
          <NavLink to="/">
            <img src={everestlogo} alt="Workaxis Logo" className="w-full" />
          </NavLink>
        </div>
        <div className="hidden md:flex items-center space-x-10 font-semibold">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "px-10 py-2 bg-primary rounded-md text-white"
                : "hover:text-quaternary"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "px-10 py-2 bg-primary rounded-md text-white"
                : "hover:text-quaternary"
            }
          >
            Products
          </NavLink>
          <NavLink
            to="/aboutus"
            className={({ isActive }) =>
              isActive
                ? "px-10 py-2 bg-primary rounded-md text-white"
                : "hover:text-quaternary"
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              isActive
                ? "px-10 py-2 bg-primary rounded-md text-white"
                : "hover:text-quaternary"
            }
          >
            Contact Us
          </NavLink>
        </div>
        <div className="md:hidden flex items-center justify-center">
          <button
            onClick={toggleMenu}
            className="focus:outline-none"
            aria-label="Toggle menu"
          >
            <div
              className={`hamburger ${
                isOpen ? "open" : ""
              } rounded-full py-4 px-3 border-2 border-primary`}
            >
              <span className="block w-7 h-1 bg-secondary rounded-md transform transition duration-300 ease-in-out"></span>
              <span className="block w-4 h-1 bg-secondary rounded-md transform transition duration-300 ease-in-out mt-1"></span>
              <span className="block w-7 h-1 bg-secondary rounded-md transform transition duration-300 ease-in-out mt-1"></span>
            </div>
          </button>
        </div>
      </nav>
      <div
        className={`md:hidden ${
          isOpen ? "block" : "hidden"
        } bg-quaternary flex h-screen flex-col items-center justify-center z-50 w-full text-white bg-black`}
      >
        <div className="flex h-full flex-col items-center justify-center mb-40 gap-y-8">
          {" "}
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "block px-10 py-3 bg-primary rounded-md"
                : "block px-4 py-2 hover:text-gray-700"
            }
            onClick={toggleMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "block px-4 py-2 bg-primary rounded-md"
                : "block px-4 py-2 hover:text-gray-700"
            }
            onClick={toggleMenu}
          >
            Products
          </NavLink>
          <NavLink
            to="/aboutus"
            className={({ isActive }) =>
              isActive
                ? "block px-4 py-2 bg-primary rounded-md"
                : "block px-4 py-2 hover:text-gray-700"
            }
            onClick={toggleMenu}
          >
            About Us
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              isActive
                ? "block px-4 py-2 bg-primary rounded-md"
                : "block px-4 py-2 hover:text-gray-700"
            }
            onClick={toggleMenu}
          >
            Contact Us
          </NavLink>
        </div>
      </div>
    </AnimatedSection>
  );
};

export default Navbar;
