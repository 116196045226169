import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import AnimatedSection from "../Animation/AnimatedSection";
import ZoomAnimated from "../Animation/ZoomAnimated";

const Map = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setMapLoaded(true);
      }
    },
  });

  return (
    <div className="p-5">
      <div className="border-2 border-slate-400 rounded-3xl flex flex-col items-center pb-3">
        <ZoomAnimated className="w-full">
          <div ref={ref} className="w-full h-450">
            {mapLoaded && (
              <iframe
                title="Google Maps Embed"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9772.619968670315!2d76.94932914148295!3d10.947591356846972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85b8c5d50de1d%3A0x9c3f74d6edada42e!2sEVEREST%20RADIATOR%20SERVICE!5e1!3m2!1sen!2sin!4v1731596209416!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-t-3xl"
              ></iframe>
            )}
          </div>
        </ZoomAnimated>
        <div className="mt-5 text-center font-primary">
          <AnimatedSection className="text-lg font-semibold">
            145/2G Edayarpalayam, Thirunavukarsu Nagar, Kuniyamuthur, Tamil Nadu
            641008
          </AnimatedSection>
          <AnimatedSection className="text-lg">
            Landline ☎️ 0422 4172327
          </AnimatedSection>
          <AnimatedSection className="text-lg">
            Mobile: +91 93842 17772, +91 93622 55672
          </AnimatedSection>
          <AnimatedSection className="text-lg">
            Email:{" "}
            <a
              href="mailto:everestradiatorservice@gmail.com"
              className="text-blue-500"
            >
              everestradiatorservice@gmail.com
            </a>
          </AnimatedSection>
        </div>
      </div>
    </div>
  );
};

export default Map;
