import React from "react";

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-8 rounded-lg max-w-lg w-[90%] h-[80%] overflow-y-scroll flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
        <p className="mb-4">
          At Everest Radiator Service Company, we are committed to protecting
          your privacy. This Privacy Policy outlines how we collect, use, and
          safeguard your information.
        </p>
        <h3 className="text-xl font-semibold mb-2">Information Collection</h3>
        <p className="mb-4">
          We collect information that you provide to us directly, such as when
          you fill out a form, subscribe to our newsletter, or contact us for
          support. This information may include your name, email address, phone
          number, and any other details you choose to provide.
        </p>
        <h3 className="text-xl font-semibold mb-2">Use of Information</h3>
        <p className="mb-4">
          We use the information we collect to provide, maintain, and improve
          our services. This includes using your information to communicate with
          you, provide customer support, and send you updates and promotional
          materials.
        </p>
        <h3 className="text-xl font-semibold mb-2">Information Sharing</h3>
        <p className="mb-4">
          We do not share your personal information with third parties except as
          necessary to provide our services or as required by law. We may share
          your information with trusted partners who assist us in operating our
          website and conducting our business, as long as those parties agree to
          keep this information confidential.
        </p>
        <h3 className="text-xl font-semibold mb-2">Data Security</h3>
        <p className="mb-4">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          Internet or electronic storage is 100% secure, and we cannot guarantee
          absolute security.
        </p>
        <h3 className="text-xl font-semibold mb-2">Your Rights</h3>
        <p className="mb-4">
          You have the right to access, correct, or delete your personal
          information. If you wish to exercise these rights, please contact us
          at everestradiatorservice@gmail.com.
        </p>
        <h3 className="text-xl font-semibold mb-2">Changes to This Policy</h3>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          You are advised to review this Privacy Policy periodically for any
          changes.
        </p>
        <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us
          at everestradiatorservice@gmail.com.
        </p>
        <button
          onClick={onClose}
          className="bg-primary text-white px-4 py-2 rounded-full hover:opacity-75 transition-opacity duration-300 mt-auto"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
